import { Ref } from 'react'
import { produce } from 'immer'
import { selector, useRecoilCallback, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { extend } from 'lodash'

import { MenuListItemProps } from '@cutover/react-ui'
import { taskListResponseState_INTERNAL } from '../tasks/task-list'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { TaskListTask } from 'main/services/queries/types'
import { accountState } from '../account/account'
import { runbookResponseState_INTERNAL } from '../runbook/runbook'
import { runbookVersionResponseState_INTERNAL } from '../runbook-version/runbook-version'

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action' }
  | { id: number; type: 'task-override' }
  | { id: number; type: 'task-override-optional' }
  | { id: number; type: 'task-override-fixed-start' }
  | { id: number; type: 'task-finish-confirm' }
  | { id: never; type: 'task-start-block' }
  | {
      id: number
      type: 'task-abandon-confirm'
      data: TaskAbandonConfirmData
    }
export type ModalActiveType =
  | ModalActiveTaskProgressionType
  | { id: number; type: 'snippet-add' }
  | { id: number; type: 'linked-runbook-add' }
  | { id: number[]; type: 'tasks-delete' }
  | { id: number[]; type: 'tasks-skip' }
  | { taskId: number; name: string; type: 'integration-abort' }

export type TaskListMenu = {
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type IntegrationRequestType = 'refire' | 'cancel'
export type IntegrationRequest = {
  taskId: number
  type: IntegrationRequestType
}

export type TaskAbandonConfirmData = {
  field_values?: any
  tasks: TaskListTask[]
  selected_successor_ids: number[]
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean | undefined>
  selectedIds: number[]
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  taskList: {
    isMenuOpen: boolean
    menu: TaskListMenu
    integrationRequest: { [x: number]: IntegrationRequestType }
  }
}

export const runbookViewState_INTERNAL = runbookAtom<RunbookViewStateType>({
  key: 'runbook-view-state',
  default: {
    loadingIds: {},
    selectedIds: [],
    modal: {
      active: undefined,
      history: []
    },
    taskList: {
      isMenuOpen: false,
      menu: {
        taskId: undefined,
        type: undefined,
        items: [],
        triggerRef: undefined,
        keyPrefix: undefined,
        minWidth: undefined,
        maxWidth: undefined,
        maxHeight: undefined
      },
      integrationRequest: {}
    }
  }
})

/* -------------------------------------------------------------------------- */
/*                                SELECTED IDS                                */
/* -------------------------------------------------------------------------- */

export const useSelectedIdsValue = () => {
  const { selectedIds } = useRecoilValue(runbookViewState_INTERNAL)
  return selectedIds
}
export const useSetSelectedIds = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const selectedIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
  })

  const selectedIdAdd = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds.push(id)
      })
    )
  })
  const selectedIdRemove = useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = draft.selectedIds.filter(draftId => draftId !== id)
      })
    )
  })
  const selectedIdToggle = useRecoilCallback(({ snapshot }) => async (id: number) => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
  })
  const selectedIdsOverwrite = useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = ids
      })
    )
  })
  const selectedIdsSelectAll = useRecoilCallback(({ snapshot }) => async () => {
    const { tasks } = await snapshot.getPromise(taskListResponseState_INTERNAL)
    selectedIdsOverwrite(tasks.map(({ id }) => id))
  })

  const selectedIdsRemoveAll = () => selectedIdsOverwrite([])

  const selectedIdsToggleAll = useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
  })

  return {
    selectedIdAdd,
    selectedIdRemove,
    selectedIdToggle,
    selectedIdsOverwrite,
    selectedIdsSelectAll,
    selectedIdsRemoveAll,
    selectedIdsToggleAll,
    selectedIdsValueCallback
  }
}

/* -------------------------------------------------------------------------- */
/*                                ACTIVE MODAL                                */
/* -------------------------------------------------------------------------- */

export const useModalActiveValue = () => useRecoilValue(runbookViewState_INTERNAL).modal.active
export const useModalHistoryValue = <TModalHistory extends object = {}>() =>
  (useRecoilValue(runbookViewState_INTERNAL) as RunbookViewStateType<TModalHistory>).modal.history
export const useSetModalActiveState = () => {
  // :!: warning: this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const modalActiveValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.active
  })
  const modalHistoryValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal.history
  })

  const modalClose = () =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = undefined
        draft.modal.history = []
      })
    )
  const modalOpen = (modal: ModalActiveType) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = modal
      })
    )
  const modalContinue = (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.modal.active = nextModal
        draft.modal.history.push(previousModal)
      })
    )

  return { modalClose, modalOpen, modalContinue, modalActiveValueCallback, modalHistoryValueCallback }
}

/* -------------------------------------------------------------------------- */
/*                                ACTIVE MENU                                 */
/* -------------------------------------------------------------------------- */

export const menuState = selector({
  key: 'runbook-menu-state',
  get: ({ getCallback }) => {
    const setMenu = getCallback(({ set }) => (menu: RunbookViewStateType['taskList']['menu']) => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          extend(draft.taskList.menu, menu)
        })
      )
    })

    const clearMenu = getCallback(({ set }) => () => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          draft.taskList.isMenuOpen = false
          draft.taskList.menu.taskId = undefined
          draft.taskList.menu.type = undefined
          draft.taskList.menu.triggerRef = undefined
          draft.taskList.menu.keyPrefix = undefined
          draft.taskList.menu.items = []
          draft.taskList.menu.minWidth = undefined
          draft.taskList.menu.maxWidth = undefined
          draft.taskList.menu.maxHeight = undefined
        })
      )
    })

    const setMenuOpenState = getCallback(({ set }) => (isOpen: boolean) => {
      set(runbookViewState_INTERNAL, previousState =>
        produce(previousState, draft => {
          draft.taskList.isMenuOpen = isOpen
        })
      )
    })

    return { setMenu, clearMenu, setMenuOpenState }
  }
})

export const useMenu = () => {
  const { taskList } = useRecoilValue(runbookViewState_INTERNAL)
  return taskList
}

export const useSetMenuState = () => {
  return useRecoilValue(menuState)
}

/* -------------------------------------------------------------------------- */
/*                                INTEGRATIONS                                */
/* -------------------------------------------------------------------------- */

export const useIntegrationRequest = () => {
  const { taskList } = useRecoilValue(runbookViewState_INTERNAL)
  return taskList.integrationRequest
}

export const useSetIntegrationRequest = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (request: IntegrationRequest) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        const { taskId, type } = request
        draft.taskList.integrationRequest[taskId] = type
      })
    )
}

export const useRemoveIntegrationRequest = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (taskId: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.taskList.integrationRequest[taskId]
      })
    )
}

export const useIntegrationRequestValue = (): [
  { [x: number]: IntegrationRequestType },
  (request: IntegrationRequest) => void
] => {
  const integrationRequest = useIntegrationRequest()
  const setIntegrationRequest = useSetIntegrationRequest()

  return [integrationRequest, setIntegrationRequest]
}

/* -------------------------------------------------------------------------- */
/*                                 LOADING IDS                                */
/* -------------------------------------------------------------------------- */

export const useLoadingIdsAllValue = () => useRecoilValue(runbookViewState_INTERNAL).loadingIds

export const useLoadingIdValue = (id: number) => useRecoilValue(runbookViewState_INTERNAL).loadingIds[id]

export const useSetLoadingIdsState = () => {
  // :!: : this function is for setters and callbacks only. Do NOT listen to any state here :!:
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const loadingIdsValueCallback = useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).loadingIds
  })

  const loadingIdAdd = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        draft.loadingIds[id] = true
      })
    )
  const loadingIdRemove = (id: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.loadingIds[id]
      })
    )
  const loadingIdAddBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => (draft.loadingIds[id] = true))
      })
    )
  const loadingIdRemoveBulk = (ids: number[]) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        ids.forEach(id => delete draft.loadingIds[id])
      })
    )
  return { loadingIdAdd, loadingIdRemove, loadingIdAddBulk, loadingIdRemoveBulk, loadingIdsValueCallback }
}

/* -------------------------------------------------------------------------- */
/*                                REQUEST STATE                               */
/* -------------------------------------------------------------------------- */

/** @description returns the combined state of all requests necessary for loading the runbook. Using this hook will make
 * requests to these endpoints if they have not been called further up in the hierarchy. It's extremely important not to use
 * this hook above the RunbookLoadingBoundary (so called at the time of writing), and even more important not to use this
 * above the runbook channel subscriber. The reason for this is because you will miss socket messages and the runbook data will
 * come out of sync (at best) or the entire page will error if a websocket message is missed and a subsequent update makes
 * reference to that missed data (eg. missing a 'create task' update, then that task being referenced in meta.changed_tasks) */
export const useRunbookRequestsValue_DANGEROUS = () => {
  const { state: accountResponse } = useRecoilValueLoadable(accountState)
  const { state: taskResponse } = useRecoilValueLoadable(taskListResponseState_INTERNAL)
  const { state: rbResponse } = useRecoilValueLoadable(runbookResponseState_INTERNAL)
  const { state: rbvResponse } = useRecoilValueLoadable(runbookVersionResponseState_INTERNAL)

  const allResponseState = [accountResponse, taskResponse, rbResponse, rbvResponse]
  const isLoading = allResponseState.includes('loading')
  const isError = allResponseState.includes('hasError')
  const isSuccess = allResponseState.includes('hasValue')

  return { isLoading, isError, isSuccess }
}

export const useRunbookRequestsState = () => {
  /** @description returns the combined state of all requests necessary for loading the runbook. Using this hook will make
   * requests to these endpoints if they have not been called further up in the hierarchy. It's extremely important not to use
   * this hook above the RunbookLoadingBoundary (so called at the time of writing), and even more important not to use this
   * above the runbook channel subscriber. The reason for this is because you will miss socket messages and the runbook data will
   * come out of sync (at best) or the entire page will error if a websocket message is missed and a subsequent update makes
   * reference to that missed data (eg. missing a 'create task' update, then that task being referenced in meta.changed_tasks) */
  const runbookRequestsValueCallback_DANGEROUS = useRecoilCallback(({ snapshot }) => () => {
    const { state: accountResponse } = snapshot.getLoadable(accountState)
    const { state: taskResponse } = snapshot.getLoadable(taskListResponseState_INTERNAL)
    const { state: rbResponse } = snapshot.getLoadable(runbookResponseState_INTERNAL)
    const { state: rbvResponse } = snapshot.getLoadable(runbookVersionResponseState_INTERNAL)

    const allResponseState = [accountResponse, taskResponse, rbResponse, rbvResponse]
    const isLoading = allResponseState.includes('loading')
    const isError = allResponseState.includes('hasError')
    const isSuccess = allResponseState.includes('hasValue')

    return { isLoading, isError, isSuccess }
  })

  return { runbookRequestsValueCallback_DANGEROUS }
}
