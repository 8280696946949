import styled, { css } from 'styled-components'

import { Box, IconButton, media } from '@cutover/react-ui'
import {
  useAiSuggestionsPanel,
  useRunbookEditPanel,
  useRunbookViewRightPanel,
  useUserOrTeamDetailsEditPanel
} from 'main/context/panel-context'
import { useRunbookId } from 'main/recoil/runbook'
import { useFeature, useLanguage } from 'main/services/hooks'

export const RunbookRightNavigation = () => {
  const { isEnabled } = useFeature()
  const { t } = useLanguage('runbook', { keyPrefix: 'rightNavigation' })
  const runbookId = useRunbookId()
  const { panel } = useRunbookViewRightPanel()
  const { openPanel: openPeoplePanel, closePanel: closePeoplePanel } = useUserOrTeamDetailsEditPanel()
  const { openPanel: openRunbookEditPanel, clearPanel: closeRunbookEditPanel } = useRunbookEditPanel()
  const { openPanel: openAiSuggestionsPanel, closePanel: closeAiSuggestionsPanel } = useAiSuggestionsPanel()

  const handleClickRunbookEdit = () => {
    if (panel?.panel === 'runbook-edit') {
      closeRunbookEditPanel()
    } else {
      openRunbookEditPanel({ runbookId })
    }
  }

  const handleClickPeoplePanel = () => {
    if (panel?.panel === 'user-team-details-edit') {
      closePeoplePanel()
    } else {
      openPeoplePanel({ initialScreen: 'users-and-teams' })
    }
  }

  const handleClickAiSuggestions = () => {
    if (panel?.panel === 'ai-suggestions-list') {
      closeAiSuggestionsPanel()
    } else {
      openAiSuggestionsPanel({ runbookId })
    }
  }

  return (
    <NavContainer>
      <IconButton
        icon="edit"
        onClick={handleClickRunbookEdit}
        label={t('runbookDetails')}
        isActive={panel?.panel === 'runbook-edit' || panel?.panel === 'runbook-copies'}
      />
      <IconButton
        icon="users"
        onClick={handleClickPeoplePanel}
        label={t('usersAndTeams')}
        data-testid="view-users-teams"
        isActive={panel?.panel === 'user-team-details-edit'}
      />
      {/* TODO see https://cutover.atlassian.net/browse/CFE-445 */}
      {/* <IconButton icon="message" onClick={handleComments} label="Comments" /> */}
      {/* TODO see https://cutover.atlassian.net/browse/CFE-446 */}
      {/* <IconButton icon="phone" onClick={handleComms} label="Ad hoc Comms" /> */}
      {isEnabled('ai_features') && (
        <Box css="position: relative;">
          <IconButton icon="rocket" onClick={handleClickAiSuggestions} label={t('aiSuggestions')} />
          <NewFeatureMarker />
        </Box>
      )}
    </NavContainer>
  )
}

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`

const NewFeatureMarker = styled(Box)`
  height: 8px;
  width: 8px;
  top: 4px;
  right: 1px;
  background-color: #e42cbc;
  border-radius: 50%;
  display: block;
  position: absolute;
`
