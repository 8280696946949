import { ReactNode, useEffect } from 'react'
import { eventManager } from 'event-manager'
import { useMount } from 'react-use'

import { StreamEditPanel, TaskEditPanel } from './right-panels'
import { UserOrTeamDetailsPanel } from './right-panels/user-team-details-panel'
import { RunbookFilter } from './runbook-filter/runbook-filter'
import { RunbookHeader } from './runbook-header/runbook-header'
import { RunbookRightNavigation } from './runbook-right-navigation/runbook-right-navigation'
import { RunbookSubHeader } from './runbook-sub-header/runbook-sub-header'
import { RunbookBanner } from '../layout/runbook-banner'
import { Layout } from 'main/components/layout'
import { RunbookCopiesPanel } from '../workspace/right-panels'
import { RunbookEditPanelTaskList } from './right-panels/runbook-edit-panel-task-list'
import { useDefaultLayout, useToggleRightPanel } from 'main/components/layout/layout-hooks'
import { useClearRightPanelOnUnmount } from 'main/context/panel-context'
import { AiSuggestionsPanel } from './right-panels/ai-suggestions/ai-suggestions-panel'

export const RunbookLayout = ({ children }: { children?: ReactNode }) => {
  const toggleRightPanel = useToggleRightPanel()
  useClearRightPanelOnUnmount()

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: true
  })

  useMount(() => {
    toggleRightPanel(false)
    eventManager.emit('close-angular-right-panel')
  })

  useEffect(() => {
    const refreshRunbookPageData = () => {
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
    }

    eventManager.on('refresh-runbook-page-data', refreshRunbookPageData)

    return () => {
      eventManager.off('refresh-runbook-page-data', refreshRunbookPageData)
    }
  }, [])

  return (
    <Layout
      header={<RunbookHeader />}
      subHeader={<RunbookSubHeader />}
      banner={<RunbookBanner />}
      filter={<RunbookFilter />}
      rightNavigation={<RunbookRightNavigation />}
      rightPanels={
        <>
          <StreamEditPanel />
          <TaskEditPanel />
          <RunbookEditPanelTaskList />
          <RunbookCopiesPanel />
          <UserOrTeamDetailsPanel />
          <AiSuggestionsPanel />
        </>
      }
    >
      {children}
    </Layout>
  )
}
