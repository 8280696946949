import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, EditPanel, IconButton, useNotify } from '@cutover/react-ui'
import { DeleteSamlConfigModal } from '../modals/delete-saml-config-modal'
import { samlConfigFormValidationSchema } from '../saml-config-form-validation-schema'
import { SamlConfigurationForm } from '../saml-configuration-form'
import { useSamlConfigEditPanel } from 'main/context/panel-context'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks'
import { SamlConfiguration } from 'main/services/queries/types'
import {
  useSamlConfiguration,
  useUpdateSamlConfigurationsMutation
} from 'main/services/queries/use-saml-configurations-query'
import { usePermissions } from 'main/services/queries/use-permissions'
import { SamlConfigurationFormDataType } from '../modals/create-new-saml-config-modal'

export const SamlConfigEditPanel = () => {
  const { samlId, clearPanel } = useSamlConfigEditPanel()
  return <>{samlId ? <SamlConfigEdit samlId={samlId} onClose={clearPanel} key={samlId} /> : null}</>
}

type SamlConfigurationsEditType = {
  samlId: number
  onClose: () => void
}

export type SamlConfigurationFormType = SamlConfiguration & {
  certificate?: string
}

const SamlConfigEdit = ({ samlId, onClose }: SamlConfigurationsEditType) => {
  const { t } = useLanguage('samlConfigurations')
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const { data, isLoading } = useSamlConfiguration(samlId)
  const patchMutation = useUpdateSamlConfigurationsMutation()
  const notify = useNotify()
  const permissions = usePermissions('saml-configs')
  const userCanEditSaml = permissions('update')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [_tabsInError, setTabsInError] = useState<string[] | undefined>(undefined)

  const defaultEditPanelValues = {
    custom_acs_url: data?.saml_configuration?.custom_acs_url,
    allowed_clock_drift: data?.saml_configuration?.allowed_clock_drift,
    default: data?.saml_configuration?.default,
    id: data?.saml_configuration?.id,
    idp_cert_multi: data?.saml_configuration?.idp_cert_multi || { signing: [] },
    idp_sso_service_url: data?.saml_configuration?.idp_sso_service_url,
    hidden: data?.saml_configuration?.hidden,
    sp_entity_id: data?.saml_configuration?.sp_entity_id,
    name: data?.saml_configuration?.name,
    options: data?.saml_configuration?.options,
    attribute_mappings: data?.saml_configuration?.attribute_mappings || {},
    certificates_with_metadata: data?.saml_configuration?.certificates_with_metadata,
    certificate: undefined
  }

  const validationSchema = samlConfigFormValidationSchema(t, 'edit')

  const onSubmit = async (formData: SamlConfiguration) => {
    patchMutation.mutate(
      { id: samlId, payload: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['saml-configurations'])
          notify.success(t('updateSamlConfiguration.toasters.success'))
          onClose()
        },
        onError: error => {
          const castedError = error as ApiError
          if (castedError?.errors) {
            setApiError(castedError?.errors.join(', '))
          }

          notify.error(t('updateSamlConfiguration.toasters.genericError'))
        }
      }
    )
  }

  const resetCertificates = () => {
    let certLength = data?.saml_configuration?.idp_cert_multi?.signing?.length || 1

    for (let i = 0; i < certLength; i++) {
      if (data?.saml_configuration?.idp_cert_multi?.signing[i] === '') {
        data?.saml_configuration?.idp_cert_multi?.signing?.splice(i, 1)
        i--
      }
    }

    let newCertMetaData = {
      cn: 'New Certificate',
      expiry: 'N/A'
    }

    data?.saml_configuration?.certificates_with_metadata?.meta_data.forEach(function (element, index) {
      if (element === newCertMetaData) {
        data?.saml_configuration?.certificates_with_metadata?.meta_data?.splice(index, 1)
      }
    })
  }

  const resetForm = () => {
    if (data) {
      data.saml_configuration.certificates_with_metadata = methods.getValues('certificates_with_metadata')

      if (data.saml_configuration.idp_cert_multi) {
        data.saml_configuration.idp_cert_multi.signing = methods.getValues('certificates_with_metadata.signing')
      }
    }
    methods.reset(data?.saml_configuration)
    setTabsInError(undefined)

    resetCertificates()
  }

  const methods = useForm<SamlConfigurationFormType>({
    defaultValues: defaultEditPanelValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleClickDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const {
    formState: { isDirty, isSubmitting }
  } = methods

  return (
    <>
      <EditPanel
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        loading={isLoading}
        onClose={onClose}
        headerItems={
          userCanEditSaml
            ? [
                <IconButton
                  label={t('samlConfigurations.editPanel.main.deleteButton.label')}
                  tipPlacement="top"
                  icon="trash-o"
                  onClick={handleClickDelete}
                />
              ]
            : []
        }
        onReset={() => {
          resetForm()
        }}
        title={t('samlConfigurations.editPanel.main.title.label')}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box>
          <FormProvider {...methods}>
            <SamlConfigurationForm
              samlConfiguration={(data?.saml_configuration || {}) as SamlConfigurationFormDataType}
              isDisabled={!userCanEditSaml}
              apiError={apiError}
            />
            <DeleteSamlConfigModal
              open={isDeleteModalOpen}
              setOpen={setIsDeleteModalOpen}
              samlId={samlId}
              onSubmit={() => {
                onClose()
              }}
            />
          </FormProvider>
        </Box>
      </EditPanel>
    </>
  )
}
