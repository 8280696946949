import { memo, useEffect } from 'react'
import { useMatch } from 'react-router-dom'

import {
  DashboardRunbookListPanel,
  FolderEditPanel,
  RunbookCopiesPanel,
  RunbookEditPanel,
  RunbookLinkedPanel,
  RunbooksBulkEditPanel,
  RunbooksDashboardSchedulePanel,
  RunbooksTimelineSummaryPanel
} from './right-panels'
import { WorkspaceFilter } from './workspace-filter'
import { WorkspaceHeader } from './workspace-header'
import { WorkspaceSubHeader } from './workspace-sub-header'
import { Layout } from 'main/components/layout'
import { useDefaultLayout, useFilterPanelLayout, useSubHeaderLayout } from 'main/components/layout/layout-hooks'
import { CreateRunbookConnector } from 'main/connectors/create-runbook-connector'
import { useAccount, useAccountDashboards } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceMeta } from 'main/services/api/data-providers/workspace'
import { LayoutLoading } from '../layout/layout-loading'
import { useClearRightPanelOnUnmount } from 'main/context/panel-context'

export const WorkspaceLayout = memo(() => {
  useClearRightPanelOnUnmount()
  const { account, isLoading } = useAccount()
  const { runbooksMeta, centralTeamsMeta } = useWorkspaceMeta()

  const { dashboardLookup } = useAccountDashboards()
  const dashboardMatch = useMatch({ path: '/app/:accountSlug/runbooks/dashboard/:dashboardId' })
  const dashboardId = dashboardMatch?.params?.dashboardId
  const dashboard = dashboardId ? dashboardLookup?.[parseInt(dashboardId)] : undefined
  const isAnalyticsDashboard = dashboard?.key === 'analytics'

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: false
  })

  const { disableFilterPanel, enableFilterPanel } = useFilterPanelLayout()
  const { disableSubHeader, enableSubHeader } = useSubHeaderLayout()

  useEffect(() => {
    if (isAnalyticsDashboard) {
      disableFilterPanel()
      disableSubHeader()
    } else {
      enableFilterPanel()
      enableSubHeader()
    }
  }, [isAnalyticsDashboard])

  if (!account || isLoading) {
    return <LayoutLoading rightNav={false} filterPanel subHeader />
  } else if (account) {
    return (
      <>
        <Layout
          header={<WorkspaceHeader account={account} />}
          subHeader={
            <WorkspaceSubHeader
              runbooksMeta={runbooksMeta}
              centralTeamsMeta={centralTeamsMeta}
              isAnalyticsDashboard={isAnalyticsDashboard}
            />
          }
          filter={isAnalyticsDashboard ? null : <WorkspaceFilter />}
          rightPanels={
            <>
              <RunbooksBulkEditPanel />
              <RunbookEditPanel />
              <RunbooksTimelineSummaryPanel />
              <DashboardRunbookListPanel />
              <RunbooksDashboardSchedulePanel />
              <RunbookCopiesPanel isWorkspacePage />
              <FolderEditPanel />
              <RunbookLinkedPanel />
            </>
          }
        />
        {/* TODO: CreateRunbookConnector will gone once create runbook flow becomes fully   See ticket https://cutover.atlassian.net/browse/CFE-266 */}
        <CreateRunbookConnector />
      </>
    )
  } else {
    return <></>
  }
})
