import { useInfiniteQuery } from 'react-query'

import { QueryKeys } from './query-keys'
import { apiClient } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { RunbookShowRunbook as Runbook, RunbookTemplateStatus } from 'main/services/queries/types'

export type SnippetListResponse = {
  meta: {
    total_results: number
  }
  snippets: SnippetListSnippet[]
}

export type SnippetListSnippet = {
  id: number
  name: string
  tasks_count: number
}

export type Snippet = Omit<Runbook, 'template_type' | 'is_template' | 'template_status'> & {
  template_type: 'snippet'
  is_template: false
  template_status: RunbookTemplateStatus
  tasks_count: number
}

type Params = {
  sort_by: string
  sort_dir: 'desc' | 'asc'
  offset: number
  limit: number
  q: string // search query
  template_status: RunbookTemplateStatus
  fields: Array<keyof Snippet>
  has_tasks: boolean
}

export function useSnippets(accountSlug: string | null, params: Partial<Params> = {}, enabled: boolean = true) {
  const getSnippets = async (offset: number) => {
    const { data } = await apiClient.get<SnippetListResponse>({
      url: `accounts/${accountSlug}/snippets`,
      params: {
        ...params,
        offset
      }
    })

    return data as SnippetListResponse
  }

  return useInfiniteQuery<SnippetListResponse, ApiError>(
    [QueryKeys.Snippets, { accountSlug, ...params, fields: params.fields?.sort() }],
    ({ pageParam = 0 }) => getSnippets(pageParam),
    {
      getNextPageParam: (lastGroup, allPages) => {
        return lastGroup?.meta?.total_results > allPages.length * (params.limit ?? 0)
          ? allPages.length * (params.limit ?? 0)
          : undefined
      },
      enabled: !!accountSlug && enabled
    }
  )
}
