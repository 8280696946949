import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Button, Message, RightPanel } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRunbookCopiesPanel, useRunbookEditPanel } from 'main/context/panel-context'
import { useLanguage } from 'main/services/hooks'
import { RunbookBaseRunbook } from 'main/services/queries/types'
import { useRunbookCopies } from 'main/services/queries/use-runbook-copies'
import { useRouting } from 'main/services/routing/hooks'

export const HEADER_HEIGHT = 64

export const RunbookCopiesPanel = ({ isWorkspacePage = false }: { isWorkspacePage?: boolean }) => {
  const { runbook, panel } = useRunbookCopiesPanel()

  return panel?.panel === 'runbook-copies' && runbook ? (
    <RunbookCopies runbook={runbook} isWorkspacePage={isWorkspacePage} />
  ) : null
}

const RunbookCopies = ({ runbook, isWorkspacePage }: { runbook: RunbookBaseRunbook; isWorkspacePage: boolean }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'copiesPanel' })
  const { clearPanel } = useRunbookCopiesPanel()
  const { openPanel: openEditPanel } = useRunbookEditPanel()
  const { data: copies, isLoading } = useRunbookCopies({ runbookId: runbook.id })
  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const { toRunbook } = useRouting()
  const navigate = useNavigate()

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  const alertMessage =
    copies && runbook.copies_count > copies.length ? (
      <Box margin={{ bottom: 'small' }}>
        <Message type="info" message={t('alert', { count: runbook.copies_count - copies.length })} />
      </Box>
    ) : null

  return (
    <RightPanel
      title={t('title')}
      onClose={clearPanel}
      onBack={() => openEditPanel({ runbookId: runbook.id })}
      loading={isLoading}
      footer={
        isWorkspacePage && (
          <Button
            onClick={() => navigate(createLinkTo(runbook.id))}
            label={t('footerButton')}
            icon="arrow-forward"
            primary
            full
          />
        )
      }
    >
      <>
        {alertMessage}
        {copies?.map(copy => {
          return <RunbookListItem key={copy.id} runbook={copy} createLinkTo={createLinkTo} />
        })}
      </>
    </RightPanel>
  )
}
