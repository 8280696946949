import { useState } from 'react'
import { eventManager } from 'event-manager'
import { Heading } from 'grommet'

import { Accordion, Icon, ListItem, Pill, RightPanel, Text } from '@cutover/react-ui'
import { useFeature, useLanguage } from 'main/services/hooks'
import { AiSuggestionItem } from './ai-suggestion-item'
import { useAiSuggestionsPanel } from 'main/context/panel-context'
import { useRunbookImprovementPrompts } from 'main/services/queries/use-runbook-ai-suggestions'

export const AiSuggestionsPanel = () => {
  const { runbookId, closePanel } = useAiSuggestionsPanel()

  return <>{runbookId && <AiSuggestionsPanelContent runbookId={runbookId} onClose={closePanel} />}</>
}

type AiSuggestionsPanelContentProps = {
  runbookId: number
  onClose?: () => void
}

type AiSuggestionPanelMode = 'prompts' | 'suggestion-item'

export const AiSuggestionsPanelContent = ({ runbookId, onClose }: AiSuggestionsPanelContentProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const [panelMode, setPanelMode] = useState<AiSuggestionPanelMode>('prompts')
  const [prompt, setPrompt] = useState<string>('')
  const { isEnabled } = useFeature()

  const handleClose = () => {
    if (isEnabled('react_runbook')) {
      onClose?.()
    } else {
      eventManager.emit('close-ai-suggestions-panel')
    }
  }

  const handleClickRowItem = (prompt: string) => {
    setPrompt(prompt)
    setPanelMode('suggestion-item')
  }

  return (
    <RightPanel
      onBack={
        panelMode === 'suggestion-item'
          ? () => {
              setPanelMode('prompts')
            }
          : undefined
      }
      title={
        <>
          <Heading truncate css="line-height: normal; font-size: 18px;" level={3} margin="none">
            {panelMode === 'prompts' ? t('title') : t('subItemTitle')}
          </Heading>
          <Pill label="New" size="medium" color="feature-pink" />
        </>
      }
      onClose={handleClose}
    >
      {panelMode === 'prompts' ? (
        <AiPromptList onClickRowItem={handleClickRowItem} />
      ) : (
        <AiSuggestionItem prompt={prompt} runbookId={runbookId} />
      )}
    </RightPanel>
  )
}

type AiSuggestionsProps = {
  onClickRowItem: (prompt: string) => void
}

const AiPromptList = ({ onClickRowItem }: AiSuggestionsProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'aiSuggestionsPanel' })
  const { data } = useRunbookImprovementPrompts()

  return (
    <>
      {data && Object.keys(data).length > 0 ? (
        <Accordion>
          {data.map(({ name: prompt, description }) => (
            <ListItem
              startComponent={<Icon icon="rocket" />}
              endComponents={[<Icon icon="chevron-right" />]}
              title={prompt}
              subTitle={description as string}
              key={prompt}
              onClick={() => {
                onClickRowItem(prompt)
              }}
            />
          ))}
        </Accordion>
      ) : (
        <Text css="white-space: normal; overflowrap: break-word;" color="text-light">
          {t('noPromptsFound')}
        </Text>
      )}
    </>
  )
}
