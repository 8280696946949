import { useCallback } from 'react'
import { TransactionInterface_UNSTABLE, useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { pullAllWith } from 'lodash'

import { taskListResponseState_INTERNAL } from '../models'
import {
  RunbookResponse,
  RunbookTaskBulkCreateResponse,
  RunbookTaskBulkDeleteResponse,
  RunbookTaskBulkSkipResponse,
  RunbookTaskCreateResponse,
  RunbookTaskFinishResponse,
  RunbookTaskIntegrationResponse,
  RunbookTaskStartResponse,
  RunbookTaskUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { updateAllChangedTasks, updateCustomFieldsData, updateTasksAndVersion } from './shared-updates'
import { taskEditUpdatedTaskId } from '../models/tasks/task-edit'
import { useTaskNotifications } from './use-task-notifications'
import { RunbookChangedTask } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'

export const useProcessTaskResponse = () => {
  const processTaskUpdateResponse = useProcessTaskUpdateResponse()
  const processTaskIntegrationResponse = useProcessTaskIntegrationResponse()
  const processTaskStartResponse = useProcessTaskStartResponse()
  const processTaskFinishResponse = useProcessTaskFinishResponse()
  const processTaskCreateResponse = useProcessTaskCreateResponse()
  const processTaskBulkDeleteResponse = useProcessTaskBulkDeleteResponse()
  const processTaskBulkSkipResponse = useProcessTaskBulkSkipResponse()
  const processTaskBulkCreateResponse = useProcessTaskBulkCreateResponse()
  const { taskStartNotification, taskFinishNotification, taskSkippedNotification, taskUpdateNotification } =
    useTaskNotifications()

  return useCallback(
    (response: RunbookResponse) => {
      // @ts-ignore version_data does not exist on all task response types (not quick_update) but this conditional is based on its presence value
      if (response.meta.version_data?.stage === 'complete') {
        window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook-version' } }))
      }

      switch (response.meta.headers.request_method) {
        case 'create':
          processTaskCreateResponse(response as RunbookTaskCreateResponse)
          break
        case 'update':
          taskUpdateNotification(response as RunbookTaskUpdateResponse)
          processTaskUpdateResponse(response as RunbookTaskUpdateResponse)
          break
        case 'start':
          taskStartNotification(response as RunbookTaskStartResponse)
          processTaskStartResponse(response as RunbookTaskStartResponse)
          break
        case 'finish':
          taskFinishNotification(response as RunbookTaskFinishResponse)
          processTaskFinishResponse(response as RunbookTaskFinishResponse)
          break
        case 'bulk_delete':
          processTaskBulkDeleteResponse(response as RunbookTaskBulkDeleteResponse)
          break
        case 'bulk_skip':
          taskSkippedNotification(response as RunbookTaskBulkSkipResponse)
          processTaskBulkSkipResponse(response as RunbookTaskBulkSkipResponse)
          break
        case 'bulk_create':
          processTaskBulkCreateResponse(response as RunbookTaskBulkCreateResponse)
          break
        case 'integration':
          processTaskIntegrationResponse(response as RunbookTaskIntegrationResponse)
          break
        default:
          console.warn('Not yet handling Task request method: ', response.meta.headers.request_method)
          return
      }
    },
    [
      processTaskCreateResponse,
      processTaskStartResponse,
      processTaskFinishResponse,
      processTaskUpdateResponse,
      processTaskBulkDeleteResponse,
      processTaskBulkSkipResponse,
      processTaskBulkCreateResponse
    ]
  )
}

export const useProcessTaskUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskUpdateResponse) => {
    const { set } = transactionInterface
    set(taskEditUpdatedTaskId, response.task)

    if (response.meta.possible_changed_fields) {
      updateCustomFieldsData(transactionInterface)(response.meta.possible_changed_fields)
    }

    const versionData = response.meta.version_data
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: versionData
    })
  })
}

export const useProcessTaskIntegrationResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskIntegrationResponse) => {
    const { set } = transactionInterface
    set(taskEditUpdatedTaskId, response.task)

    const versionData = response.meta.version_data
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      task: response.task,
      versionData: versionData
    })
  })
}

export const useProcessTaskCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskCreateResponse) => {
    const versionData = response.meta.version_data
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks as RunbookChangedTask[],
      versionData: versionData
    })
  })
}

export const useProcessTaskStartResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskStartResponse) => {
    const versionData = response.meta.version_data
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      task: response.task,
      versionData: versionData
    })
  })
}

export const useProcessTaskFinishResponse = () => {
  return useRecoilCallback(({ snapshot, set, reset }) => async (response: RunbookTaskFinishResponse) => {
    const transactionInterface: TransactionInterface_UNSTABLE = {
      get: recoilValue => snapshot.getLoadable(recoilValue).getValue(),
      set,
      reset
    }

    const versionData = response.meta.version_data
    if (versionData?.stage === 'complete') {
      updateAllChangedTasks(transactionInterface)(response.meta.changed_tasks)

      // Dispatching custom events outside Recoil state management
      window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent('refresh-data-store', { detail: { type: 'runbook' } }))
    } else {
      updateTasksAndVersion(transactionInterface)({
        changedTasks: response.meta.changed_tasks,
        task: response.task,
        versionData: versionData
      })
    }
  })
}

// TODO: does not handle deleted_runbook_component_ids updates
export const useProcessTaskBulkDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskBulkDeleteResponse) => {
    const { set } = transactionInterface

    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })

    // performance-wise: make more sense to do this before or after updateTasksAndVersion
    set(taskListResponseState_INTERNAL, prevTaskResponse =>
      produce(prevTaskResponse, draftTaskResponse => {
        pullAllWith(draftTaskResponse.tasks, response.meta.deleted_task_ids, (task, deletedId) => task.id === deletedId)
      })
    )
  })
}

// Handles skipping either single or multiple tasks
// NOTE: keeping this separate because it has unhandled meta data
// TODO: does not handle comments updates
export const useProcessTaskBulkSkipResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskBulkSkipResponse) => {
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}

// NOTE: keeping this separate because it has unhandled `new_task_ids` in meta respose. If we do not have to handle
// this, can just use the `useProcessTaskUpdateResponse` handler
// TODO: does not handle new_task_ids updates: However, do we need to distinguish this if we're iterating over changed tasks and adding any tasks that aren't there to the task collection
export const useProcessTaskBulkCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookTaskBulkCreateResponse) => {
    updateTasksAndVersion(transactionInterface)({
      changedTasks: response.meta.changed_tasks,
      versionData: response.meta.version_data
    })
  })
}
