import { useRecoilValue } from 'recoil'

import {
  taskListTaskState,
  useAccountProperty,
  useModalActiveValue,
  useRunbookId,
  useRunbookVersionProperty,
  useSetModalActiveState
} from 'main/recoil/runbook'
import { AddSnippetModal } from '../../modals/snippets/add-snippet/add-snippet-modal'
import { LinkTemplateSelectWizard } from '../../modals/link-template/link-template-select/link-template-select-wizard'
import {
  TaskAbandonConfirmModal,
  TaskActionModal,
  TaskFinishConfirm,
  TaskFixedStartOverride,
  TaskOverride,
  TasksDeleteModal,
  TaskSkip
} from '../../modals/task-modals'
import { TaskIntegrationAbortModal } from '../../modals/task-modals/task-integration-abort-modal'

export const TaskListModals = () => {
  const activeModal = useModalActiveValue()
  const { modalClose } = useSetModalActiveState()

  return (
    <>
      {activeModal?.type === 'snippet-add' && (
        <AddSnippetModal initiallyOpen taskId={activeModal?.id} onClose={modalClose} />
      )}
      {activeModal?.type === 'linked-runbook-add' && (
        <LinkTemplateSelectWizardRecoilWrapper onClose={modalClose} id={activeModal?.id} />
      )}
      {activeModal?.type === 'tasks-delete' && <TasksDeleteModal taskIds={activeModal.id} open onClose={modalClose} />}
      {activeModal?.type === 'task-action' && <TaskActionModal onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'task-override' && <TaskOverride onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'task-override-fixed-start' && (
        <TaskFixedStartOverride id={activeModal.id} onClose={modalClose} />
      )}
      {activeModal?.type === 'task-finish-confirm' && <TaskFinishConfirm onClose={modalClose} id={activeModal.id} />}
      {activeModal?.type === 'tasks-skip' && <TaskSkip onClose={modalClose} ids={activeModal.id} />}
      {activeModal?.type === 'task-abandon-confirm' && (
        <TaskAbandonConfirmModal onClose={modalClose} id={activeModal.id} data={activeModal.data} />
      )}
      {activeModal?.type === 'integration-abort' && (
        <TaskIntegrationAbortModal open onClose={modalClose} taskId={activeModal.taskId} name={activeModal.name} />
      )}
    </>
  )
}

const LinkTemplateSelectWizardRecoilWrapper = ({ id, onClose }: { onClose: () => void; id: number }) => {
  const task = useRecoilValue(taskListTaskState(id))
  const accountSlug = useAccountProperty({ attribute: 'slug' })
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionProperty({ attribute: 'id' })

  return (
    <LinkTemplateSelectWizard
      onClose={onClose}
      accountId={accountSlug}
      onSubmit={() => {}}
      task={task}
      runbookId={String(runbookId)}
      runbookVersionId={String(runbookVersionId)}
    />
  )
}
